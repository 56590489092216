<template>
  <!-- THIS IS ITEM SUB MENU
  IF HAVE SOME CHILD ACTIVE WILL BE PARENT OF SUB SUB MENU, AFTER CLICK CAN SHOW SUB SUB MENU
  IF HAVE CHILD BUT EVERY CHILD IS NOT ACTIVE AND CURRENT ROUTE IS ONE OF CHILD THEN MARK WITH BOLD FONT
  IF NOT HAVE SOME CHILD AS SINGLE AND THE ROUTE IS CURRENT ROUTE THEN MARK WITH BOLD FONT  -->
  <router-link
    :to="
      isHasChildActive
        ? ''
        : !!props.data.redirect
        ? { path: props.data.redirect }
        : { name: props.data.name }
    "
    @click.prevent="toggleChild"
    :class="[
      {
        'font-semibold': currentRouteAsSingle,
      },
      {
        'bg-white-1 rounded-full font-semibold': show || currentRouteAsParent,
      },
    ]"
    class="flex relative items-center gap-2 py-2 pl-9 px-3 text-black text-sm hover:bg-white-1 hover:rounded-full transition-all duration-400 ease-in-out"
  >
    {{ $t(props.data.meta.title) }}
    <icon-svg
      v-if="isHasChild && isHasChildActive"
      :class="{ 'rotate-180': show }"
      class="absolute max-sm:hidden transition-all duration-400 ease-in-out -translate-y-1/2 top-1/2 right-3.5"
      icon="IconArrowDown"
      :width="10"
      :height="8"
    />
  </router-link>
  <Transition name="expand">
    <!-- IF HAS A CHILD SHOW MENU AFTER CLICK PARENT / SUB MENU -->
    <ul
      v-if="isHasChild && show && sidebar.showSidebar"
      class="overflow-hidden mt-2 grid gap-2"
    >
      <li v-for="x in props.data.children" :key="x.name">
        <router-link
          v-if="!x.hidden"
          :to="{ name: x.name }"
          :class="{ 'font-semibold': route.name == x.name }"
          class="flex relative items-center gap-2 py-2 pl-9 px-3 text-black text-sm hover:bg-white-1 hover:rounded-full transition-all duration-400 ease-in-out"
        >
          {{ $t(x.meta.title) }}
        </router-link>
      </li>
    </ul>
  </Transition>
</template>

<script setup>
import { useMainStore } from "@/stores/main";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
const sidebar = useMainStore();
const route = useRoute();
const props = defineProps({
  data: {
    type: Object,
    default: function () {
      return {
        path: "#",
        title: "Live Tracking",
        name: "live-tracking",
        icon: "icon-live-tracking",
        children: [],
      };
    },
  },
});
const isHasChild = computed(() => {
  return props.data.children && props.data.children.length;
});
const isHasChildActive = computed(() => {
  return (
    isHasChild.value &&
    props.data.children.some((a) => {
      return !a.hidden;
    })
  );
});
const currentRouteAsParent = computed(() => {
  if (isHasChildActive.value) {
    let find = props.data.children.some((x) => route.name == x.name);
    return find;
  } else {
    return false;
  }
});
const currentRouteAsSingle = computed(() => {
  if (isHasChild.value) {
    let find = props.data.children.some((x) => route.name == x.name);
    return find;
  } else if (route.name == props.data.name) {
    return true;
  } else {
    return false;
  }
});
var show = ref(false);
const toggleChild = () => {
  if (
    props.data.children &&
    props.data.children.length &&
    props.data.children.some((a) => {
      return !a.hidden;
    })
  ) {
    sidebar.setSidebar(true);
    // setTimeout(() => {
    show.value = !show.value;
    // }, 100);
  }
};
onMounted(() => {
  if (currentRouteAsParent.value) {
    show.value = true;
  }
});
</script>
