import { Workbook } from "exceljs";
const PRIMARY_COLOR = "FFFAA61A";
import { read, utils } from "xlsx";

export const downloadExcel = (header, data, fileName) => {
  let workbook = new Workbook();
  let worksheet = workbook.addWorksheet(`${fileName}`);
  let headerRow = worksheet.addRow(header);
  headerRow.eachCell((cell) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: PRIMARY_COLOR },
      bgColor: { argb: "FF0000FF" },
    };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });
  data.forEach((d) => {
    worksheet.addRow(d);
  });
  return { workbook, worksheet };
};

export const excelToJsonReader = (excelFile) => {
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.readAsBinaryString(excelFile);
    reader.onload = (e) => {
      var data = e.target.result;
      var workbook = read(data, {
        type: "binary",
        // cellDates: true, dateNF: 'dd/mm/yyy;@'
        cellText: true,
      });
      workbook.SheetNames.forEach(async function (sheetName) {
        var XL_row_object = utils.sheet_to_row_object_array(
          workbook.Sheets[sheetName],
          { raw: false }
        );
        var jsonArray = JSON.parse(JSON.stringify(XL_row_object));
        resolve(jsonArray);
      });
    };
    reader.onerror = function (ex) {
      reject(ex);
    };
  });
};
