<template>
  <div id="app">
    <!-- Add key for reload on the new page -->
    <!-- <router-view :key="route.path"></router-view> -->
    <router-view></router-view>
    <confirmation-dialog />
    <loading-overlay />
    <file-viewer />
    <modal-idle />
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/scss/icon.scss";
@import "@/assets/styles/scss/custom.scss";
</style>

<script setup>
import { useRouter } from "vue-router";
import { useWindowSize } from "@vueuse/core";
import { onMounted, watch } from "vue";
import { useMainStore } from "./stores/main";
import ConfirmationDialog from "./components/modules/general/ConfirmationDialog.vue";
import LoadingOverlay from "./components/modules/general/LoadingOverlay.vue";
import FileViewer from "./components/modules/files/FileViewer.vue";
import ModalIdle from "./components/modules/general/ModalIdle.vue";

import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { useToast } from "vue-toastification";
import { useNotificationStore } from "./stores/notification/notification";

// const route = useRoute();
const router = useRouter();
const mainStore = useMainStore();
const notifStore = useNotificationStore();
const toast = useToast();
// indentification catgeory of size the screen
const { width } = useWindowSize();
watch(width, (value) => {
  mainStore.setScreenSize(value);
});

// register firebase
const firebaseConfig = {
  apiKey: "AIzaSyBCMyeskNCIkQ9PSzNvR6GfGYJ_iNveYCk",
  authDomain: "modena-mologiz-dev.firebaseapp.com",
  projectId: "modena-mologiz-dev",
  storageBucket: "modena-mologiz-dev.appspot.com",
  appId: "1:883370935852:web:ffb82e1904537a2304f389",
  messagingSenderId: "883370935852",
  measurementId: "G-4V3R3YLN9J",
};
initializeApp(firebaseConfig);
// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
onMounted(() => {
  mainStore.setScreenSize(width.value);
  const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    toast.info(payload.data.body, {
      onClick: () => {
        showDetail(payload.data);
      },
    });
    notifStore.getHeader();
    // ...
  });
});
function showDetail(item) {
  const codeShipments = [
    "NOTIF.CATEGORY.01",
    "NOTIF.CATEGORY.02",
    "NOTIF.CATEGORY.03",
  ];
  const codeInvoices = ["NOTIF.CATEGORY.04", "NOTIF.CATEGORY.05"];
  if (codeShipments.includes(item.category)) {
    router.push("/order/history/detail/" + item.referenceID);
  } else if (codeInvoices.includes(item.category)) {
    router.push("/finance/billing-history/detail/" + item.referenceID);
  }
}
</script>
<style>
#app {
  min-width: 350px;
}
</style>
