import { defineStore } from "pinia";
import { encrypt, decrypt } from "@/utils/crypto";
import {
  token,
  user,
  ltl,
  tokenFcm,
  currentBranch,
  // listBranches,
} from "@/utils/crypto-global";
import { resetRouter, router } from "@/router/index";
import { publicRoute } from "@/router/config";
import {
  login,
  register,
  checkAccount,
  forgotPassword,
  // getInfo,
  getAuthorization,
  resetPassword,
  checkFeatureLTL,
  getProfile,
  updateProfile,
  getCompany,
  updateCompany,
  logout,
  setBillingAddress,
  getQuestions,
  updateSecurityQuestion,
  updatePassword,
  getRoles,
  getCompanyCategories,
  switchApp,
  reqSwitchApp,
  submitOtp,
  resendOtp,
  sendOtpExistingAccount,
  changeEmailExistingAccount,
  submitOtpEmailExistingAccount,
  removeAccount,
  refreshToken,
  changeBranch,
} from "@/api/user";
import { protectedRoute } from "@/router/config";
import { useMainStore } from "./main";
import { useToast } from "vue-toastification";
// const defaultUser = {
//   username: "",
//   email: "",
//   account_code: "",
//   role_name: "",
//   avatar: "",
//   companyName: "",
//   company_category: "",
//   initial_name: "",
//   featureLtl: "",
// };
const toast = useToast();

export const useUserStore = defineStore("user", {
  state: () => ({
    token: localStorage.getItem(token) || null,
    user: localStorage.getItem(user)
      ? decrypt(localStorage.getItem(user))
      : null,
    LTL: localStorage.getItem(ltl) ? decrypt(localStorage.getItem(ltl)) : null,

    currentBranch: localStorage.getItem(currentBranch)
      ? decrypt(localStorage.getItem(currentBranch))
      : null,
    showSelectBranch: false,
    // listBranches: localStorage.getItem(listBranches)
    //   ? decrypt(localStorage.getItem(listBranches))
    //   : [],
    routes: null,
    roles: null,
    listRoles: [],
    questions: [],
    listCompanyCategory: [],
    fcmToken: localStorage.getItem(tokenFcm) || null,
  }),
  getters: {
    getUser: (state) => {
      return (attr) => state.user[attr];
    },
    hasRoles: (state) => {
      return (attr) => state.roles.some((x) => x == attr);
    },
    isB2c: (state) => {
      if (state.user) {
        return state.user.companyType.includes("CL1");
      }
      return false;
    },
  },
  actions: {
    setFcmToken(token) {
      this.fcmToken = token;
      localStorage.setItem(tokenFcm, encrypt(token));
    },
    setRoutes(payload) {
      this.routes = publicRoute.concat(payload).map((x) => {
        x.show = false;
        return x;
      });
      for (let idx = 0; idx < payload.length; idx++) {
        router.addRoute(payload[idx]);
      }
    },
    toggleRoute(path) {
      this.routes = this.routes.map((x) => {
        x.show = x.path == path ? !x.show : false;
        return x;
      });
    },
    setUser(payload) {
      this.user = payload;
      localStorage.setItem(user, encrypt(payload));
    },
    setToken(payload) {
      this.token = payload;
      localStorage.setItem(token, encrypt(payload));
    },
    setLtl(payload) {
      this.LTL = payload;
      localStorage.setItem(ltl, encrypt(payload));
    },
    setRoles(payload) {
      this.roles = payload;
    },
    setShowSelectBranch(payload) {
      this.showSelectBranch = payload;
    },
    // setListBranches(payload) {
    //   this.listBranches = payload;
    // },
    setCurrentBranch(payload) {
      this.currentBranch = payload;
      localStorage.setItem(currentBranch, encrypt(payload));
    },
    login(payload) {
      const { email, password, remember } = payload;
      return new Promise((resolve, reject) => {
        login({
          email: email,
          password: password,
          remember: remember,
          fcmToken: this.fcmToken,
          type: "web",
        })
          .then((response) => {
            if (!response) {
              reject("Tidak ada response dari server");
            } else {
              handleTokenResponse(response, resolve);
              response.data = decrypt(response.data);
              let userLang = response.data.language;
              if (userLang) {
                const storeMain = useMainStore();
                let lang = userLang.slice(3, 5);
                storeMain.setLang(lang);
              }
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    generateRoutes(roles) {
      return new Promise((resolve) => {
        let accessedRoutes;
        if (roles.includes("admin")) {
          accessedRoutes = protectedRoute || [];
        } else {
          accessedRoutes = filterAsyncRoutes(protectedRoute, roles);
        }
        this.setRoutes(accessedRoutes);
        resolve(accessedRoutes);
      });
    },
    // check access to feature ltl
    checkFeatureLTL() {
      return new Promise((resolve, reject) => {
        checkFeatureLTL()
          .then((response) => {
            this.setLtl(response.data.allowed);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // user register default
    register(payload) {
      payload.fcmToken = this.fcmToken;
      payload.type = "web";
      return new Promise((resolve, reject) => {
        register(payload)
          .then((response) => {
            if (!response) {
              reject("Tidak ada response dari server");
            } else {
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    checkAccount(payload) {
      return new Promise((resolve, reject) => {
        checkAccount(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    submitOtp(payload) {
      return new Promise((resolve, reject) => {
        submitOtp(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    resendOtp(payload) {
      return new Promise((resolve, reject) => {
        resendOtp(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    sendOtpExistingAccount(payload) {
      return new Promise((resolve, reject) => {
        sendOtpExistingAccount(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    changeEmailExistingAccount(payload) {
      return new Promise((resolve, reject) => {
        changeEmailExistingAccount(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    submitOtpEmailExistingAccount(payload) {
      return new Promise((resolve, reject) => {
        submitOtpEmailExistingAccount(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    forgotPassword(payload) {
      return new Promise((resolve, reject) => {
        forgotPassword(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    resetPassword(payload) {
      return new Promise((resolve, reject) => {
        resetPassword(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    reqSwitchApp() {
      return new Promise((resolve, reject) => {
        reqSwitchApp()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    switchApp(payload) {
      return new Promise((resolve, reject) => {
        switchApp(payload)
          .then((response) => {
            response.data = response.payload;
            handleTokenResponse(response, resolve);
            response.data = decrypt(response.data);
            let userLang = response.data.language;
            if (userLang) {
              const storeMain = useMainStore();
              let lang = userLang.slice(3, 5);
              storeMain.setLang(lang);
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    changeBranch(payload) {
      return new Promise((resolve, reject) => {
        changeBranch(payload)
          .then((response) => {
            this.setShowSelectBranch(false);
            this.setToken(response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get user info default
    // getInfo() {
    //   return new Promise((resolve, reject) => {
    //     getInfo(this.token)
    //       .then((response) => {
    //         if (!response) {
    //           reject("Verification failed, please Login again.");
    //         } else {
    //           const roles = response.roles;
    //           // roles must be a non-empty array
    //           if (!roles || roles.length <= 0) {
    //             reject("getInfo: roles must be a non-null array!");
    //           } else {
    //             // set language
    //             let userLang = this.getUser("language");
    //             if (userLang) {
    //               const storeMain = useMainStore();
    //               let lang = userLang.slice(3, 5);
    //               storeMain.setLang(lang);
    //             }
    //             // set roles
    //             this.setRoles(roles);
    //             resolve(response);
    //           }
    //         }
    //       })
    //       .catch((error) => {
    //         console.log("getInfo(): error: " + error);
    //         reject(error);
    //       });
    //   });
    // },

    getAuthorization() {
      return new Promise((resolve, reject) => {
        getAuthorization(this.token)
          .then((response) => {
            if (!response) {
              reject("Verification failed, please Login again.");
            } else {
              let data = decrypt(response.data);
              const roles = data.permissions;
              // roles must be a non-empty array
              if (!roles || roles.length <= 0) {
                reject("getInfo: roles must be a non-null array!");
              } else {
                // set language
                let userLang = this.getUser("language");
                if (userLang) {
                  const storeMain = useMainStore();
                  let lang = userLang.slice(3, 5);
                  storeMain.setLang(lang);
                }
                // set roles
                this.setRoles(roles);
                resolve(data);
              }
            }
          })
          .catch((error) => {
            console.log("getInfo(): error: " + error);
            reject(error);
          });
      });
    },

    // get profile
    getProfile(payload) {
      return new Promise((resolve, reject) => {
        getProfile(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update profile
    updateProfile(payload) {
      return new Promise((resolve, reject) => {
        updateProfile(payload)
          .then((response) => {
            // set language
            if (payload.has("language")) {
              const storeMain = useMainStore();
              let lang = payload.get("language").slice(3, 5).toLowerCase();
              storeMain.setLang(lang);
              let newUser = this.user;
              newUser.language = payload.get("language");
              this.setUser(newUser);
            }
            // set avatar
            let newUser = this.user;
            if (payload.has("avatar")) {
              var reader = new FileReader();
              reader.onload = function (e) {
                let newFile = e.target.result;
                newUser.avatar = newFile;
              };
              reader.readAsDataURL(payload.get("avatar"));
            }
            if (payload.has("name")) {
              newUser.full_name = payload.get("name");
            }
            setTimeout(() => {
              this.setUser(newUser);
            }, 2000);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    refreshToken(payload = {}) {
      return new Promise((resolve, reject) => {
        refreshToken(payload)
          .then((response) => {
            // set new token
            let newUser = decrypt(response.data);
            if (newUser && newUser.token) {
              this.setToken(newUser.token);
            } else {
              toast.error("Error set new token");
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get profile
    getCompany(payload) {
      return new Promise((resolve, reject) => {
        getCompany(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update Company
    updateCompany(payload) {
      return new Promise((resolve, reject) => {
        updateCompany(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // set main billing address
    setBillingAddress(payload) {
      return new Promise((resolve, reject) => {
        setBillingAddress(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get list questions
    getQuestions() {
      return new Promise((resolve, reject) => {
        getQuestions()
          .then((response) => {
            this.questions = response.data;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get list company category
    getCompanyCategories() {
      return new Promise((resolve, reject) => {
        getCompanyCategories()
          .then((response) => {
            this.listCompanyCategory = response.data;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get list questions
    getRoles() {
      return new Promise((resolve, reject) => {
        getRoles()
          .then((response) => {
            this.listRoles = response.data;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update security question
    updateSecurityQuestion(payload) {
      return new Promise((resolve, reject) => {
        updateSecurityQuestion(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update password
    updatePassword(payload) {
      return new Promise((resolve, reject) => {
        updatePassword(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update password
    removeAccount(payload) {
      return new Promise((resolve, reject) => {
        removeAccount(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // user logout custom
    logout() {
      return new Promise((resolve, reject) => {
        logout()
          .then((response) => {
            if (!response) {
              reject("Tidak ada response dari server");
            } else {
              this.setToken(null);
              this.setRoles(null);
              this.setLtl(null);
              this.setCurrentBranch(null);
              localStorage.clear();
              sessionStorage.clear();
              resetRouter();
              resolve();
            }
          })
          .catch((error) => {
            this.setToken(null);
            this.setRoles(null);
            this.setLtl(null);
            localStorage.clear();
            sessionStorage.clear();
            resetRouter();
            // errorHandler(error)
            reject(error);
          });
      });
    },
  },
});

function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some((role) => route.meta.roles.includes(role));
  } else {
    return true;
  }
}

export function filterAsyncRoutes(routes, roles) {
  const res = [];
  routes.forEach((route) => {
    const tmp = { ...route };
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });

  return res;
}

const handleTokenResponse = async (response, resolve) => {
  if (response.status !== "error" && response.data.status !== "error") {
    // decrypt result response data
    let result = decrypt(response.data);
    // set to Store and local storage
    if (
      result.account_code == "100" ||
      result.account_code == 100 ||
      result.company_id.client_type == "CL2" ||
      result.company_id.client_type == "CL3"
    ) {
      const user = {
        username: result.username,
        full_name: result.fullName,
        email: result.email,
        account_code: result.account_code,
        account_verified: result.account_verified,
        role_name: result.roleName[0],
        avatar: result.avatar,
        company_name: result.company_id.name,
        company_verified: result.company_id.verify,
        company_category: result.company_category_id.name,
        company_id: result.company_id._id,
        initial_name: result.company_id.initial_name,
        language: result.language ? result.language : "ID-ID",
        featureLtl: "",
        logo: result.company_id.logo_upload,
        companyType: [result.company_id.client_type],
        services: result.company_id.application_service
          ? result.company_id.application_service
          : [],
      };
      useUserStore().setUser(user);
      useUserStore().setToken(result.token);
      const data = await useUserStore().getAuthorization();
      await useUserStore().generateRoutes(data.permissions);

      // set warehouse
      if (result.branch) {
        // Example result current branch
        // {
        //   "id": "6697519e58cebb0e05577e2b",
        //   "name": "JATAKE 2",
        //   "picName": "Finance Modena",
        //   "picNo": "02129969595",
        //   "address": "Garut Kota, Kabupaten Garut, Jawa Barat, Indonesia",
        //   "long": 106.8549881,
        //   "lat": -6.2251223
        // }
        useUserStore().setCurrentBranch(result.branch);
      }

      resolve(response);
    }
  }
};
