import { useToast } from "vue-toastification";
const toast = useToast();
export function required(v) {
  if (typeof v == "string") {
    let text = v.length > 0 ? v.trim() : v;
    return !!text;
  } else if (typeof v == "boolean") {
    return !!v;
  } else {
    return !!v;
  }
}

export function password(value) {
  const containsUppercase = /[A-Z]/.test(value);
  const containsLowercase = /[a-z]/.test(value);
  const containsNumber = /[0-9]/.test(value);
  const containsSpecial = /[@$!%*?&]/.test(value);
  return (
    containsUppercase && containsLowercase && containsNumber && containsSpecial
  );
}

export function requiredNumberDecimal(value) {
  return parseFloat(value) > 0;
}

export function requiredNumber(value) {
  return parseInt(value) > 0;
}

export function validNumber(value) {
  return /^[0-9.,]+$/.test(value);
}

export function validCode(value) {
  if (value) {
    // eslint-disable-next-line
    let newVal = /^[[a-zA-Z0-9_@\\.\,\-\s\/]+$/.test(value);
    if (newVal) return value.trim().length == value.length;
    return false;
  }
  return true;
}

export function validString(value) {
  if (value) return /^[a-zA-Z0-9 .,-]+$/.test(value);
  return true;
}

export function validPhone(v) {
  if (v !== "" && v !== null && v !== undefined)
    return /^(0)[0-9]{4,13}$/.test(v);
  return true;
}

export function validFile(
  value,
  validType = ["pdf", "doc", "docx", "png", "jpg", "jpeg"],
  maxSize = 5,
  maxFiles = 1
) {
  let result = [];
  Object.keys(value).forEach((x) => {
    result.push(value[x]);
  });
  let validFormat = result.every((x) => {
    let formatFile = x.name
      .substring(x.name.lastIndexOf(".") + 1)
      .toLowerCase();
    return validType.includes(formatFile.toLowerCase());
  });
  let validSize = result.every((x) => {
    let maximaze = maxSize * 1000000;
    let maxiFile = x.size;
    return maxiFile < maximaze;
  });
  if (validFormat && validSize && result.length <= maxFiles) {
    return true;
  } else {
    if (!validFormat) {
      toast.warning(
        `Format lampiran tidak di dukung, unggah lampiran dengan format ${validType.join(
          ", "
        )}`
      );
    } else if (!validSize) {
      toast.warning(`Ukuran lampiran maksimal ${maxSize}MB`);
    } else if (result.length > maxFiles) {
      toast.warning(`Maksimal lampiran adalah ${maxFiles} File`);
    }
  }
}
