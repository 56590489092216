export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test"])},
  "validations_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["validations are used for Vuelidate support."])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is not filled in."])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid url"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email, e.g., name", "@", "email.com"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This field requires a minimum of ", _interpolate(_named("min")), " characters."])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This field allows a maximum of ", _interpolate(_named("max")), " characters."])},
    "maxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This field allows a maximum of ", _interpolate(_named("max")), "."])},
    "minValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This field allows a minimum of ", _interpolate(_named("min")), "."])},
    "minLengthNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This field requires a minimum of ", _interpolate(_named("min")), "."])},
    "maxLengthNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This field allows a maximum of ", _interpolate(_named("max")), "."])},
    "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords must contain at least one number, lowercase letter, uppercase letter, and special character ", "(@$!%*?&)"])},
    "requiredNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field must be greater than zero."])},
    "requiredNumberDecimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field must be greater than zero."])},
    "validNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field only allows numbers."])},
    "validPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number is invalid"])},
    "validString": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field only allows ", "a-z A-Z 0-9 .-,"])},
    "validCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field only allows ", "a-zA-Z 0-9.,_-@/"])}
  },
  "el_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["el is used for language in every element component."])},
  "el": {
    "switch_view": {
      "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grid"])},
      "row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row"])}
    },
    "select_check": {
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])},
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
      "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
    },
    "list_checkbox": {
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])},
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])}
    },
    "file_input": {
      "warning_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unsupported file format. Please upload a file with the format ", _interpolate(_named("msg")), "."])},
      "warning_size": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum file size is ", _interpolate(_named("msg")), "Mb."])},
      "warning_amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum number of files is ", _interpolate(_named("msg")), "."])}
    },
    "field_time": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operational hour"])},
      "open_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Time"])},
      "close_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed Time"])}
    }
  },
  "modules_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["m is used for language in every module component."])},
  "m": {
    "filter": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Filter"])},
      "labels": {
        "delivery_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Date"])},
        "invoice_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Date"])}
      },
      "filter_status": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Status"])}
      },
      "filter_delivery_confirm": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Confirmation"])},
        "items": {
          "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
          "not_yet_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not yet confirmed"])}
        }
      },
      "filter_payment_status": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])},
        "items": {
          "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
          "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partial"])},
          "unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid"])},
          "refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
          "refund_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Process"])},
          "refund_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Success"])}
        }
      },
      "filter_delivery_type": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Type"])}
      },
      "filter_is_dedicated": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedicated"])},
        "items": {
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
        }
      },
      "filter_overdue": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])},
        "items": {
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
        }
      },
      "filter_truck": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Type"])}
      },
      "filter_sub_truck": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Truck Type"])}
      },
      "filter_load": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Type"])}
      },
      "filter_invoice_category": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Category"])}
      },
      "filter_billing_category": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Category"])}
      },
      "filter_goods_status": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "items": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
          "nonactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not active"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
          "arcive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])}
        }
      },
      "filter_driver_license": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver License Type"])}
      },
      "filter_notif_status": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "items": {
          "unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haven't Read"])},
          "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have Read"])},
          "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])}
        }
      },
      "filter_webhook_method": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])}
      },
      "filter_services": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])}
      },
      "filter_yes_no": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "items": {
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
        }
      },
      "filter_status_active": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "items": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
          "nonactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not active"])}
        }
      }
    },
    "header_auth": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])}
    },
    "footer_auth": {
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERSION"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Mologiz Delivery App"])}
    },
    "header_main": {
      "account": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
        "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
        "back_old_screen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Previous Display"])}
      },
      "notification": {
        "not_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unread"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Notifications"])},
        "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show All"])},
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Order"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notifications yet. Create an order and receive related order notifications."])},
        "empty_unverified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notifications yet."])}
      }
    },
    "calendar": {
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note:"])},
      "note_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated charge pickup within 3-5 hours after ordering time."])}
    },
    "email_verification": {
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Your Account"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By verifying your account, you can:"])},
        "points": {
          "1": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information from MOLOGIZ directly enters your e-mail"])},
            "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can accept the notification directly about ordering from MOLOGIZ to your e-mail easily."])}
          },
          "2": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOLOGIZ Promo Access"])},
            "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodically, we send promos to e-mail registered in the verified MOLOGIZ account. Make sure you are not missing our promos!"])}
          },
          "3": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smooth Transaction"])},
            "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account will not be blocked and you can continue to order MOLOGIZ."])}
          }
        },
        "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit your account"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
        "email_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your e-mail"])},
        "email_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can change your email or use e-mail that has been registered in this account."])},
        "email_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: You can't change your e-mail back in the future."])},
        "email_your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
        "email_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change E-mail"])},
        "email_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the e-mail for verification"])},
        "email_input_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the email"])},
        "email_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the email"])},
        "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
        "otp_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the OTP code"])},
        "otp_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter 6 digits of OTP code that has been sent to your e-mail"])},
        "otp_validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP code has not been filled in"])},
        "otp_not_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not accepting the OTP code?"])},
        "otp_countdown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wait ", _interpolate(_named("time")), " seconds for"])},
        "otp_resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resending"])},
        "success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification was successful"])},
        "success_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulation! Your verification is successful. You can continue your transaction at MOLOGIZ."])},
        "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
        "next_with_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Continue with e-mail ", _interpolate(_named("email")), "?"])}
      }
    },
    "card": {
      "card_address": {
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
      },
      "card_address_item": {
        "goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])},
        "amount_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Items"])},
        "sn_client_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Order No"])},
        "name_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender's Full Name"])},
        "name_receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver's Full Name"])},
        "phone_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender's Phone Number"])},
        "phone_receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver's Phone Number"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])}
      },
      "card_date_note": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and Note"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup Date"])},
        "pickup_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup Time"])},
        "no_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Number"])},
        "fleet_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleet Completeness"])},
        "standard_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Equipments"])},
        "specific_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Equipments"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "pickup_time_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup estimated in 3-5 hours"])}
      },
      "card_service_empty": {
        "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon"])}
      },
      "card_shipment": {
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
        "reorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reorder"])},
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Details"])}
      },
      "card_shipment_redirect": {
        "info_favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your favorite orders from the Order History menu"])},
        "info_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an order in the Create Order menu"])},
        "order_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order History"])},
        "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Order"])}
      },
      "card_contract": {
        "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "truck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck"])},
        "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])}
      },
      "card_contract_redirect": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract is not available."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact MOLOGIZ to place an order."])}
      }
    },
    "services": {
      "FTL": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FTL"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Truck Load"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order feature per Unit Vehicle. By using this feature, your items will not be mixed with other senders."])}
      },
      "LTL": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LTL"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less Than Truck Load"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partial order feature. You will save costs by sharing space with other senders."])}
      },
      "FCL": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCL"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Container Load"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order feature per container. By using this feature, your items will not be mixed with other senders."])}
      },
      "LCL": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCL"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less Container Load"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partial order feature with container."])}
      }
    },
    "table": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "add_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single"])},
      "bulky_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk upload"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
      "download_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Selected"])},
      "download_standard_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Standard Format"])},
      "download_specific_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Specific Format"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data"])},
      "delete_mark": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete mark (", _interpolate(_named("msg")), ")"])},
      "delete_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Selected"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
      "cancel_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel selected"])},
      "all_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select ", _interpolate(_named("count")), " all data"])},
      "page_data_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>", _interpolate(_named("selected")), "</b> data on this page has been all selected."])},
      "all_data_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>", _interpolate(_named("selected")), "</b> all selected data."])},
      "data_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>", _interpolate(_named("selected")), "</b> selected data."])},
      "selected_from": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("selected")), " data selected from ", _interpolate(_named("all")), " data on this page"])},
      "no_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data selected"])},
      "actions": {
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      }
    },
    "general": {
      "toast_new_content": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New content is available"])},
        "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])}
      },
      "confirm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Reason"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and agreed"])},
        "text": {
          "change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change status?"])},
          "delete_mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the data marked?"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete data?"])},
          "do_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save data?"])},
          "do_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the data?"])}
        }
      },
      "countdown": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleet Search"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
        "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hour"])},
        "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minute"])},
        "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second"])}
      },
      "list_text": {
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data"])}
      },
      "modal_idle": {
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The system has detected that you have not been active for 1 hour. You will be automatically logged out in:"])}
      },
      "modal_select_branch": {
        "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a branch"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can change the branch"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search a branch ..."])},
        "pic_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC Name"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no branch"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])}
      }
    },
    "order": {
      "address": {
        "valid_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type the appropriate address"])}
      },
      "completeness": {
        "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Equipments"])},
        "specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Equipments"])}
      },
      "upload_goods": {
        "list_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item List"])},
        "add_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Item"])},
        "edit_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Item"])},
        "name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Name"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
        "confirm_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save item data?"])},
        "confirm_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit item data?"])},
        "select_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select item"])},
        "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Code"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
        "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
        "net_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Weight (Kg)"])},
        "gross_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Weight (Kg)"])},
        "cubication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cubication"])},
        "vol_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume Weight"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
        "used_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use of Serial Number"])},
        "with_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With serial number"])},
        "no_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial Number"])},
        "no_serial_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the serial number of items"])},
        "no_serial_ph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter serial number"])},
        "no_serial_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: Use a coma when inputting more than one serial number"])},
        "no_serial_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "no_serial_not_same": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of serial numbers does not match with quantity of item, please check again"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "empty_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No item data yet. Please add item or bulk upload item"])},
        "upload_bulk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk Upload Item"])},
        "upload_excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Excel"])},
        "download_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Template"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "invalid_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid value"])},
        "table": {
          "headers": {
            "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
            "no_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Code"])},
            "name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Name"])},
            "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
            "lwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LxWxH (cm)"])},
            "net_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Weight (Kg)"])},
            "gross_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Weight (Kg)"])},
            "cubication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cubication (m<sup>3</sup>)"])},
            "vol_metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume Weight (kgv)"])},
            "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX( GW VS VW)"])},
            "is_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Serial"])},
            "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
          },
          "actions": {
            "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
            "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
          }
        },
        "information": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
          "point1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure to upload the excel file in the provided template format"])},
          "point2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the quantity value is not filled or filled with zero, it will be changed to the default value of 1"])}
        },
        "clear_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear Data"])},
        "clear_mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Selected"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "view_goods": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item List"])},
        "outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "name_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name - Phone Number"])},
        "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Attachment"])},
        "view_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Attachment"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "table": {
          "headers": {
            "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
            "serial_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Code"])},
            "name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Name"])},
            "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
            "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P(cm)"])},
            "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L(cm)"])},
            "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T(cm)"])},
            "net_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Weight (Kg)"])},
            "gross_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Weight (Kg)"])},
            "cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cubication (m<sup>3</sup>)"])},
            "subtotal_cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal Cubication (m<sup>3</sup>)"])},
            "return_qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Quantity"])},
            "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])}
          }
        },
        "total_cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Cubication"])},
        "total_gross_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Gross Weight"])}
      },
      "view_serials": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of Serial Number"])},
        "item_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Code"])},
        "item_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Name"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent Status"])},
        "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])}
      },
      "change_route": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Address"])},
        "outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "new_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Full Address"])},
        "placeholder_new_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new full address"])},
        "reason_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for Change"])},
        "placeholder_reason_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter reason for change"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
      },
      "choose_address": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Address"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No address found. Please add an address in"])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])}
      },
      "reasons": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Order"])},
        "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasons"])},
        "placeholder_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Reasons"])},
        "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Reasons"])},
        "placeholder_other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Other Reasons"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "no_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another reason cannot be empty"])}
      },
      "shipment_methods": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Method"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The delivery method has not been selected"])},
        "01": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery by tenants (WMS)"])},
          "short_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TENANT WMS"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set delivery using your own transportation."])}
        },
        "02": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery by tenants"])},
          "short_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TENANT"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set delivery using your own transportation."])}
        },
        "03": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery by MOLOGIZ"])},
          "short_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOLOGIZ"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOLOGIZ will help you to arrange delivery transportation."])}
        },
        "04": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery by MOLOGIZ (WMS)"])},
          "short_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOLOGIZ WMS"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOLOGIZ will help you to arrange delivery transportation."])}
        },
        "05": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery by tenants (Dedicated)"])},
          "short_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEDICATED"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The fleet that you rent is fully managed by MOLOGIZ to ensure efficient delivery."])}
        },
        "06": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery by tenants (WMS Dedicated)"])},
          "short_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEDICATED (WMS)"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The fleet that you rent is fully managed by MOLOGIZ to ensure efficient delivery."])}
        }
      },
      "shipment_routes": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route"])},
        "route_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route Type"])},
        "route_type_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select route type"])},
        "one_to_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One point of origin and one point of destination"])},
        "many_to_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipickup (Multiple points of origin, one point of destination)"])},
        "one_to_many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multidrop (one origin point, multiple destination points)"])}
      }
    },
    "crop_image": {
      "image_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position Image"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
    },
    "preview_file": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview Attachment"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download All"])}
    }
  },
  "layout_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l is the language for every layout."])},
  "l": {
    
  },
  "pages_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This language is for every page."])},
  "dashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "filters": {
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
      "prev_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Month"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Month"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Week"])},
      "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])}
    },
    "cards": {
      "new_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Orders"])},
      "onprogress_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders in Progress"])},
      "complete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed Orders"])},
      "unpaid_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid Invoices"])},
      "paid_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid Invoices"])},
      "due_date_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date Invoices"])}
    },
    "chart_donut": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution of Delivery Destinations"])},
      "empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create more orders to see the distribution diagram of your deliveries."])}
    },
    "chart_line": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Deliveries"])},
      "empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Orders to See the Comparison Diagram of Your Deliveries."])}
    },
    "remainder": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There are <b>", _interpolate(_named("msg")), " bills</b> that are due, Please settle the payments immediately to avoid any intervention to your orders."])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the bill"])}
    }
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to your MOLOGIZ account to get started"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name", "@", "email.com"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember Me"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
    "login_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])}
  },
  "register": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "account_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in your personal information to create a MOLOGIZ account"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name", "@", "email.com"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "confirm_password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register As"])},
    "role_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Role"])},
    "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Data"])},
    "personal_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide your personal information. Your name will be displayed on your profile."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Full Name"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Question"])},
    "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Security Question"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
    "answer_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer to Security Question"])},
    "register_as_corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as a company?"])},
    "register_as_corporate_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "register_as_corporate_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "nik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIK KTP"])},
    "nik_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your NIK KTP"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "phone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "shipping_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Address"])},
    "shipping_address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your delivery address"])},
    "bank_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select bank"])},
    "no_bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Account No"])},
    "no_bank_account_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the bank account number"])},
    "name_bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Account Name"])},
    "name_bank_account_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the bank account name"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Information"])},
    "company_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company data will be used as the billing address"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Company's Name"])},
    "pic_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC Name"])},
    "pic_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Full Name"])},
    "pic_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC Phone Number"])},
    "pic_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC Phone Number"])},
    "company_npwp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company NPWP"])},
    "company_npwp_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company NPWP Number"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Email"])},
    "company_email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact", "@", "email.com"])},
    "company_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Phone"])},
    "company_phone_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
    "company_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "company_npwp_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Address"])},
    "company_npwp_address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company address according to NPWP"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Address"])},
    "company_address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your company address"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])}
  },
  "forgot_password": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will send a security code to reset your password."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name", "@", "email.com"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Answer"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Email"])}
  },
  "reset_password": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "security_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Code"])},
    "security_code_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Code"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "new_password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your New Password"])},
    "new_password_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
    "new_password_confirm_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enter Your New Password"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])}
  },
  "reset_password_success": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Successful"])},
    "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your request to reset the password was successful. Please check your inbox <b>", _interpolate(_named("email")), "</b> as we will send the security code link to that email."])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Understand"])}
  },
  "otp_verification": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP Verification"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the 6 digit verification code received on your Email ID"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification Code"])},
    "label_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get the verification code in the email that we sent"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re -send the code"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification code has not been filled"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification"])},
    "error_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is not found"])}
  },
  "order_create": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Order"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Service"])},
    "favourite": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite Order"])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Options"])}
    },
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
        "typeTruck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Type"])},
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
        "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
      },
      "actions": {
        "reorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reorder"])}
      }
    },
    "service": {
      "no_fcl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCL Coming Soon"])},
      "disable_ltl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LTL is not yet available on your account, please contact admin Mologiz"])},
      "disable_title_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Not Yet Available"])},
      "disable_content_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact our sales for more information"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, contact MOLOGIZ to use the service being disabled"])}
    }
  },
  "order_draft": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Draft"])},
    "table": {
      "headers": {
        "shipmentNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Shipment Draft"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
        "originAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Address"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "destinationAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Address"])},
        "cubics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cubics (CBM)"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
      },
      "actions": {
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Details"])}
      }
    },
    "modal_create_shipment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Shipment from Draft"])},
      "selected_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Selected Drafts"])},
      "total_cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Cubication (CBM)"])},
      "order_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Type"])},
      "route_optimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route Optimization"])},
      "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
      "load_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length of Load Time (in minutes)"])},
      "load_time_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter loading time"])},
      "drop_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length of Drop Time (in minutes)"])},
      "drop_time_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter load time"])},
      "total_fleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Fleet"])},
      "total_fleet_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter total fleet"])},
      "warehouse_capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle capacity in the warehouse"])},
      "warehouse_capacity_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter capacity of the vehicle in the warehouse"])},
      "max_destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum destination by shipping"])},
      "max_destination_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter maximum destination by shipping"])},
      "max_fleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum fleet capacity"])},
      "max_fleet_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter maximum fleet capacity"])},
      "shipment_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Shipment Type"])},
      "shipment_type_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment Type"])},
      "truck_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Truck Type"])},
      "truck_type_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Type"])},
      "sub_truck_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Sub Truck Type"])},
      "sub_truck_type_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Truck Type"])},
      "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Load Type"])},
      "load_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Type"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment Date"])},
      "date_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date has not been filled"])},
      "additional_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Information"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "save_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to make shipment?"])}
    },
    "modal_success_created": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment Created"])},
      "label1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delivery with ", _interpolate(_named("reservation")), " draft number has been made with <b> ", _interpolate(_named("shipment")), " </b> Shipping number:"])},
      "label2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["And there is <b> ", _interpolate(_named("total")), " </b> shipping number that cannot be made because the route is beyond reach:"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can see this data back in your MOLOGIZ notification inbox."])}
    }
  },
  "order_detail_draft": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft Order Details"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Draft Order"])},
    "document_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Draft Order"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "total_cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Cubication"])}
  },
  "order_history": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order History"])},
    "favorite_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite Orders"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "shipmentNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment No"])},
        "etd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETD"])},
        "shipmentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "originAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
        "originOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Outlet"])},
        "destinationAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "destinationOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Outlet"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
      },
      "actions": {
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Details"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit LTL Order"])},
        "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Draft Order"])}
      }
    }
  },
  "order_ftl": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create FTL Order"])},
    "search_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Options"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "contractNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Contract"])},
        "originOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Outlet"])},
        "originAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Address"])},
        "destinationOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Outlet"])},
        "destinationAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Address"])},
        "typeTruck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Type - Subtype"])},
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Type"])},
        "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
      },
      "actions": {
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])}
      }
    }
  },
  "order_ftl_form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add FTL Order"])},
    "information": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Information"])},
      "shipper_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipper Name"])},
      "no_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Number"])},
      "truck_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Type"])},
      "load_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Type"])},
      "backhaul_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backhaul Price"])},
      "multidrop_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multidrop Price"])},
      "multipickup_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipickup Price"])},
      "regular_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Price"])}
    },
    "nav": {
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
      "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Order"])}
    },
    "tab": {
      "tab1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin, Destination, and Load Details"])},
        "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "outlet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet Name"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "sn_client_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Order No"])},
        "sn_client_no_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Delivery Order Number"])},
        "fullname_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender's Full Name"])},
        "fullname_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient's Full Name"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Phone"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])},
        "address_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender's Full Address"])},
        "address_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient's Full Address"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])},
        "open_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening Hours"])},
        "close_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing Hours"])},
        "choose_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Vehicle"])},
        "select_truck_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select truck type"])},
        "select_sub_truck_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Sub Truck Type"])}
      },
      "tab2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Date and Additional Information"])},
        "date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date & Time of Delivery"])},
        "date_time_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery will be conducted on:"])},
        "set_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Date"])},
        "set_time_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
        "oclock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "additional_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Information"])},
        "amount_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Amount"])},
        "no_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference No"])},
        "no_ref_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference number can be filled with AJU number, container, barcode, and other supporting document numbers"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Attachment"])},
        "file_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload SO, DO, PO, or other supporting delivery documents with a maximum size of 5MB"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])}
      },
      "tab3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Review"])},
        "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipper"])},
        "vehicle_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type - sub vehicle type"])},
        "cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cubication"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
        "total_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Items"])}
      }
    },
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "originOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Outlet"])},
        "originAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Address"])},
        "destinationOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Outlet"])},
        "destinationAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Address"])},
        "typeTruck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Type - Subtype"])},
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Type"])},
        "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])}
      },
      "actions": {
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
        "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Order?"])},
        "order_without_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to upload document attachment, order still accepted without attachment"])}
      }
    }
  },
  "order_fcl": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create FCL Order"])}
  },
  "order_fcl_form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add FCL Order"])}
  },
  "order_ltl_form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create LTL Order"])},
    "select_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Address"])},
    "add_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Origin"])},
    "add_destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Destination"])},
    "max_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum 30 origin"])},
    "max_desti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum 30 destination"])}
  },
  "edit_ltl_form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit LTL Order"])},
    "sub_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the order for the delivery of"])},
    "change_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the Document Attachment"])},
    "view_file_prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the previous document"])},
    "change_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Order"])}
  },
  "edit_draft_form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Draft Order"])},
    "select_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select From Draft"])},
    "select_draft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Destination from Draft"])},
    "select_draft_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Draft"])},
    "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "order_detail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Details"])},
    "cancel_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Order"])},
    "delivery_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery History"])},
    "info_detail": {
      "no_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment No"])},
      "etd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated Delivery Date"])},
      "truck_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Type"])},
      "truck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck"])},
      "load_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Type"])},
      "no_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Number"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Attachment"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
      "no_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No attachment yet"])},
      "view_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View File"])},
      "shipping_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Schedule"])},
      "view_shipping_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Shipping Schedule"])}
    },
    "payment": {
      "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
      "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Selected"])},
      "search_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment methods can be selected when the Fleet Search process is complete."])},
      "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill"])},
      "waiting_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for the best price"])},
      "desc_waiting_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The best price will be given to you after the search for the fleet is complete."])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay bills"])}
    },
    "standard_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Equipments"])},
    "specific_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Equipments"])},
    "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Confirmation"])},
    "desc_confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery data cannot be changed once you confirm this delivery."])},
    "contract_nofound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract No Found"])},
    "receipt_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation of receipt of goods"])},
    "sure_cancel_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sure to cancel shipment ?"])},
    "sure_accept_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By pressing the “Confirmation“ button, I stated that the goods had arrived at the destination and were received."])},
    "route": {
      "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Address"])},
      "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Address"])},
      "view_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Item List"])},
      "view_add_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Additional Cost"])},
      "goods_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a return of items"])},
      "change_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change address"])},
      "sure_change_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sure to change the address ?"])},
      "card": {
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "name_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender's Full Name"])},
        "name_receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver's Full Name"])},
        "operational_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operational Hour"])},
        "no_open_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening hours are not specified"])},
        "no_close_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing hours are not specified"])}
      }
    },
    "cost_detail": {
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Cost"])},
      "total_cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Cubication"])},
      "min_cubic": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If the total cubication is less than ", _interpolate(_named("msg")), " CBM, then it will be rounded to be ", _interpolate(_named("msgg")), " CBM"])},
      "total_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Weight"])},
      "min_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If the total weight is less than ", _interpolate(_named("msg")), " kg, then it will be rounded to be ", _interpolate(_named("msgg")), " kg"])},
      "contract_params": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This shipment uses a parameter ", _interpolate(_named("msg"))])},
      "table": {
        "headers": {
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
        }
      }
    },
    "add_cost": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details of Additional Shipper Costs"])},
      "info_desti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route Information"])},
      "outlet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet Name"])},
      "status_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Status"])},
      "name_receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver's Full Name"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "no_sj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Order No"])},
      "file_sj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment"])},
      "view_sj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Attachment"])},
      "add_cost_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of Additional Fees"])},
      "no_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No note"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Cost"])},
      "table": {
        "headers": {
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
          "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
        }
      }
    },
    "rebroadcast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reorder"])},
    "confirmation_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Confirmation"])},
    "sure_rebroadcast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sure reorder?"])},
    "refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a Refund"])},
    "shipping_schedule": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Schedule"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "shipping_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping No"])},
      "cruise_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship Name"])},
      "voy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOY"])},
      "closing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Date"])},
      "shipping_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Date"])},
      "eta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETA Date"])}
    },
    "assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment"])},
    "change_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the assignment"])},
    "modal_internal_assigment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment"])},
      "title_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change The Assignment"])},
      "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver"])},
      "driver_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose driver"])},
      "fleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleet"])},
      "fleet_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose fleet"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
      "reason_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the reason"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to assign?"])},
      "confirm_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to replace assignment?"])},
      "result_same": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver data and fleet are not replaced because it is the same as before."])}
    }
  },
  "not_found": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found!"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't worry, check your URL again to ensure there are no typing mistakes."])},
    "sub_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also go back to the Home page to continue browsing MOLOGIZ."])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "master_address": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Addresses"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "table": {
      "actions": {
        "switch": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
          "nonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])}
        },
        "change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Status?"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "delete_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete customer address?"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add customer address ?"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit customer address ?"])}
      },
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])},
        "outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Phone"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "operational_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operational Hour"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
      },
      "content": {
        "no_open_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening hours have not been determined"])},
        "no_close_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing hours have not been determined"])}
      }
    },
    "form": {
      "detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Details"])},
      "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Customer Address"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "ph_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter name"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Phone"])},
      "ph_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter mobile phone"])},
      "open_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operational Opening Hours"])},
      "close_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operational Closing Hours"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
      "ph_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter note"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])},
      "ph_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Full Address"])},
      "outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet"])},
      "ph_outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter outlet"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
    }
  },
  "master_fleet": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleet"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "home_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pool"])},
        "plat_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plat Number"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Type"])},
        "sub_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Truck Type"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
      },
      "actions": {
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])}
      }
    },
    "form": {
      "detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleet Detail"])},
      "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Fleet"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "home_base_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose pool"])},
      "plat_no_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter plat number"])},
      "type_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose truck type"])},
      "sub_type_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose sub truck type"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
    }
  },
  "master_pool": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pool"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pool Name"])},
        "pic_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC Name"])},
        "pic_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC Number"])},
        "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
        "zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
      },
      "actions": {
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])}
      }
    },
    "form": {
      "detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Details"])},
      "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Pool"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "name_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter name"])},
      "pic_name_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter PIC name"])},
      "pic_no_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter PIC number"])},
      "district_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type district"])},
      "zip_code_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter zip code"])},
      "address_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter full address"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
    }
  },
  "profile_address": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Name"])},
        "pic_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC Name"])},
        "pic_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC Number"])},
        "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
        "zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
      },
      "actions": {
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])}
      }
    },
    "form": {
      "detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Details"])},
      "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Address"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "name_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter name"])},
      "pic_name_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter PIC name"])},
      "pic_no_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter PIC number"])},
      "district_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type district"])},
      "zip_code_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter zip code"])},
      "address_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter full address"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
    }
  },
  "contract": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
        "origin_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Address"])},
        "destination_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Address"])},
        "fleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleet"])},
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Type"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "addcost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Cost"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
      },
      "actions": {
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
      },
      "content": {
        "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipper"])},
        "no_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract No"])},
        "outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlet"])},
        "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City/Regency"])},
        "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
        "overnight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overnight"])},
        "multipickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipickup"])},
        "multidrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multidrop"])}
      }
    },
    "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available"])}
  },
  "contract_detail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Details"])},
    "no_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract No"])},
    "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipper"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Type"])},
    "fleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Type"])},
    "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Type"])},
    "info_rute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route Information"])},
    "rute": {
      "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin Address"])},
      "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Address"])}
    },
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "price_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Price"])},
        "price_backhaul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backhaul Price"])},
        "overnight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overnight"])},
        "multipickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipickup"])},
        "multidrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multidrop"])},
        "start_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Period"])},
        "end_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Period"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])}
      }
    },
    "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City/Regency"])},
    "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
    "price_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price List"])},
    "full_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])}
  },
  "goods": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item List"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "delete_mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive Mark"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "switch": {
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "nonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])}
    },
    "form": {
      "detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Details"])},
      "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Item"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "no_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Code"])},
      "ph_no_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter item code"])},
      "name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Name"])},
      "ph_name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter item name"])},
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
      "ph_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter length"])},
      "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
      "ph_l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter width"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
      "ph_t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter height"])},
      "net_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Weight"])},
      "ph_net_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter net weight"])},
      "gross_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Weight"])},
      "ph_gross_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter gross weight"])},
      "cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cubication"])},
      "vol_metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumetric Weight"])},
      "max_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX (GW VS VW)"])},
      "is_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Serial"])},
      "ph_is_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select is serial"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "edit_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Item?"])},
      "add_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Item?"])}
    },
    "table": {
      "actions": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Data"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk Upload"])},
        "change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Status?"])},
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
        "do_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate ?"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive?"])},
        "delete_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive of item?"])},
        "delete_marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive items marked?"])}
      },
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "no_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Code"])},
        "name_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Name"])},
        "plt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L/W/H (cm)"])},
        "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length (cm)"])},
        "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width (cm)"])},
        "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height (cm)"])},
        "net_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Weight (Kg)"])},
        "gross_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Weight (Kg)"])},
        "cubic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cubication (m<sup>3</sup>)"])},
        "vol_metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumetric Weight (kgv)"])},
        "max_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX (GW VS VW)"])},
        "is_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Serial"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      }
    }
  },
  "finance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice History"])},
    "warning_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment status cannot be empty"])},
    "table": {
      "actions": {
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Details"])},
        "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
        "pay_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Invoices"])},
        "chip": {
          "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
          "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partial"])},
          "unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid"])},
          "refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
          "refund_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Process"])},
          "refund_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Success"])}
        }
      },
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "invoice_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice No"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
        "invoice_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Date"])},
        "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      }
    }
  },
  "finance_detail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Details"])},
    "invoice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invoice ", _interpolate(_named("msg"))])},
    "telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "npwp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax ID"])},
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear"])},
    "invoice_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice No"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "transfer_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer To:"])},
    "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A.C (Account No)"])},
    "name_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A.N (Account Name)"])},
    "total_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Delivery Cost"])},
    "ppn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tax ", _interpolate(_named("msg"))])},
    "total_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Invoice"])},
    "total_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Payment"])},
    "invoice_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Remaining"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized Signature."])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "download_factur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Factur"])},
    "download_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Invoice"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Invoice"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "shipment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment Date"])},
        "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])}
      }
    }
  },
  "notification": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications will be automatically deleted after 7 days"])},
    "filter_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "mark_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as Read"])},
    "change_item": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to archive the notification?"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete the notification?"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has the notification been read?"])}
    },
    "change_items": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive selected notifications?"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete selected notifications?"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark selected notifications as read?"])}
    },
    "open_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Link"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notifications at the moment.<br /> Create orders and get related order notifications soon."])}
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "actions": {
      "confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes?"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    },
    "billing_address": {
      "main_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Address"])},
      "create_main_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Main Address"])}
    },
    "tab": {
      "account": {
        "private_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Data"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
        "ph_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter full name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "ph_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
        "ph_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter username"])},
        "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Role"])},
        "ph_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter access role"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
        "ph_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter mobile phone number"])},
        "delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete your account"])},
        "delete_account_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting the account will make you unable to use the service anymore.The system will automatically send your account Evidence of Deleting your account"])},
        "modal_delete_account": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Delete Account"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting an account means that it will delete all your information from our database."])},
          "confirm_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To confirm the deletion, type 'DELETE'"])},
          "confirm_input_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type here"])},
          "reason_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasons to delete your account"])},
          "reason_input_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the reason"])},
          "confirm_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sure delete your account?"])},
          "word_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The word is not appropriate"])}
        }
      },
      "billing": {
        "billing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Data"])},
        "account_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Account"])},
        "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Name"])},
        "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account No"])},
        "name_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Name"])},
        "ph_name_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter account name"])},
        "document_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Document Completeness"])},
        "cover_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Book Cover"])},
        "no_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No attachment"])},
        "ready_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to Upload"])},
        "edit_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Attachment"])},
        "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Attachment"])},
        "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Address"])},
        "no_billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Billing Address"])},
        "create_main_billing_address": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Make ", _interpolate(_named("msg")), " the main billing address?"])},
        "success_billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Set as Main Address"])},
        "select_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select bank"])},
        "main_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Account"])},
        "create_main_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Main Account"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "enter_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter city"])},
        "account_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account"])},
        "enter_account_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter no account"])},
        "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Account Name"])},
        "add_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a New Account Number"])},
        "delete_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the account?"])},
        "set_default_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a main account?"])},
        "form_add_account_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new bank account"])},
        "form_company_account_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Account"])},
        "first_name_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account First Name"])},
        "enter_first_name_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the first name"])},
        "last_name_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Last Name"])},
        "enter_last_name_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter account last name"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "save_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
      },
      "company": {
        "company_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Data"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
        "ph_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Email"])},
        "ph_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company email"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Phone"])},
        "ph_phone_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "ph_phone_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter phone number"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Address"])},
        "ph_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company address"])},
        "company_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Completeness"])},
        "npwp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Tax ID"])},
        "ph_npwp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company tax ID"])},
        "nib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company NIB"])},
        "ph_nib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter NIB"])},
        "document_completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Completeness"])},
        "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Logo"])},
        "ready_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to Upload"])},
        "edit_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Attachment"])},
        "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Attachment"])},
        "no_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No attachment yet"])}
      },
      "branch": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
        "table": {
          "headers": {
            "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No."])},
            "branch_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch Name"])},
            "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
            "picContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC Information"])}
          },
          "actions": {
            "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
          }
        },
        "modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Map"])},
          "title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Branch"])},
          "do_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the branch?"])},
          "branch_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch Name"])},
          "pic_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC Name"])},
          "ph_pic_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter pic name"])},
          "pic_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC Number"])},
          "ph_pic_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter pic number"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
          "ph_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter address"])}
        }
      },
      "notification": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
        "order_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Status"])},
        "payment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])},
        "new_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Order"])},
        "received_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Received"])},
        "to_destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the way to Destination"])},
        "load_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Items"])},
        "complete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Completed"])},
        "create_new_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating New Invoice"])},
        "over_due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue Invoice"])},
        "invoice_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Paid"])}
      },
      "security": {
        "security_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Security"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Question"])},
        "ph_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter security question"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
        "ph_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter answer"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
        "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
        "confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
        "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password?"])}
      },
      "web_hook": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "table": {
          "headers": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
            "credential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credential"])},
            "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])},
            "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
            "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
            "type_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication Type"])},
            "secret_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret Token"])}
          },
          "actions": {
            "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
            "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
            "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
            "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
            "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
            "nonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
            "change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Status?"])},
            "delete_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete data?"])}
          }
        },
        "modal": {
          "ask_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Webhook?"])},
          "ask_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Webhook?"])},
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Webhook"])},
          "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook Details"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
          "ph_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter name"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
          "ph_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the URL, Exp: http://www.facebook.com"])},
          "auth_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auth URL"])},
          "ph_auth_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the URL, Exp: http://www.facebook.com"])},
          "ref_db": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database reference"])},
          "ph_ref_db": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the database reference"])},
          "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
          "ph_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter username"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
          "ph_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])},
          "secret_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret Token"])},
          "ph_secret_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter secret Token"])},
          "auth_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication type"])},
          "ph_auth_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the authentication type"])},
          "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])},
          "ph_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the method"])},
          "other_needs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Needs"])},
          "must_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login is needed"])},
          "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger"])},
          "closed_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery has been verified by MOLOGIZ"])},
          "cancel_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Cancellation"])},
          "return_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a constraint for delivery of goods"])},
          "drop_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery has reached the destination"])},
          "assignment_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment or replacement of fleets and drivers"])},
          "goods_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The inbound process has added stock in the warehouse"])},
          "goods_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The outbound process has reduced stock in the warehouse"])},
          "inventory_transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The process of transferring goods from the initial warehouse to the destination warehouse"])},
          "transfer_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The process of transferring goods from the location/initial zone to the destination location/zone"])},
          "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The return process of goods"])},
          "ssl_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSL Verification"])},
          "activate_ssl_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate SSL verification"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
        }
      },
      "web_hook_history": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook History"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
        "filter_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of request"])},
        "table": {
          "headers": {
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger"])},
            "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])},
            "time_goes_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time goes on"])},
            "demand_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demand Time"])},
            "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
          },
          "actions": {
            "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])}
          }
        },
        "modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History Details"])},
          "time_goes_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Goes On"])},
          "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
          "demand_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demand Time"])},
          "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])},
          "copy_code_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The code was successfully copied"])},
          "download_json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download JSON"])},
          "success_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully download data"])},
          "failed_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to download data"])}
        }
      },
      "token": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "date_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The period of validity"])},
        "table": {
          "headers": {
            "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
            "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
            "last_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last used"])},
            "expire_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired date"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
            "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
            "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
          },
          "actions": {
            "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
            "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
            "delete_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete data?"])}
          }
        },
        "modal": {
          "ask_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Token?"])},
          "ask_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Token?"])},
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Token"])},
          "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detil Token"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
          "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
          "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse"])},
          "ph_warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select warehouse"])},
          "ph_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select service"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
          "ph_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter title"])},
          "expire_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired Date"])},
          "ph_expire_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the expiration date"])},
          "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
          "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
          "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
          "last_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last used"])},
          "ph_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the notes here"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
        }
      }
    },
    "menu": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Data"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Data"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Data"])},
      "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Security"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "web_hook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook"])},
      "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
      "avatar_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar successfully changed"])}
    },
    "table": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])}
    }
  },
  "revision_history": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revision History"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "no_data_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data to download"])},
    "success_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data download successful"])},
    "table": {
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "invoice_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice No"])},
        "billing_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Company"])},
        "invoice_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Date"])},
        "invoice_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Category"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Invoice"])},
        "factur_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Number"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      },
      "headers_expand": {
        "invoice_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice No"])},
        "invoice_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Date"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated Date"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])}
      }
    }
  },
  "live_tracking": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleet Tracking"])},
    "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Updated ", _interpolate(_named("msg"))])},
    "shipment_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment No"])},
    "shipment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment Date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "truck_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck Type"])},
    "sub_truck_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Truck Type"])},
    "origin_route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
    "destination_route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "police_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police No"])},
    "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver"])},
    "driver_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver Phone"])},
    "success_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment successfully copied"])},
    "success_copy_shp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment No successfully copied"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "nothing_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No shipments"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "list_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment List"])},
    "filter": {
      "active_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Shipments"])},
      "latest_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Shipments"])},
      "longest_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longest Shipments"])},
      "latest_etd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest ETD"])},
      "longest_etd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longest ETD"])}
    }
  },
  "list_staff": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff List"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "table": {
      "actions": {
        "switch": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
          "nonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])}
        },
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
        "change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Status?"])}
      },
      "headers": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "access_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
        "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Phone Number"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
      }
    },
    "form": {
      "detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Details"])},
      "add_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Staff"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
      "ph_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter full name"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
      "ph_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter username"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "ph_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Phone Number"])},
      "ph_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter mobile phone number"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
      "ph_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter role"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])},
      "ph_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter full address"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "ph_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
      "ph_confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter confirm password"])},
      "security_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Question"])},
      "ph_security_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter security question"])},
      "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
      "ph_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter answer"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "added_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Staff?"])},
      "change_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Staff?"])}
    }
  },
  "list_driver": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver List"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])},
    "verified_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The driver has been verified by the OTP in the driver's MOLOGIZ application."])},
    "unverified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not yet verified"])},
    "unverified_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The driver has not verified the OTP for the driver's MOLOGIZ application."])},
    "table": {
      "actions": {
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])}
      },
      "headers": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Phone"])},
        "phone_emergency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency Contact Driver"])},
        "license_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver License Type"])},
        "verif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
      }
    },
    "form": {
      "detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver Detail"])},
      "title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Driver"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The driver's e-mail is used to access shipment information in the MOLOGIZ Driver App application. Please fill in with a valid e-mail address."])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
      "view_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Photo"])},
      "name_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter driver name"])},
      "phone_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter driver mobile phone"])},
      "phone_emergency_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter emergency contact driver"])},
      "card_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KTP ID"])},
      "card_id_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter KTP ID"])},
      "email_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email"])},
      "drivers_license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver License"])},
      "drivers_license_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter driver license"])},
      "license_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver License Type"])},
      "license_type_ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose driver license type"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    }
  },
  "routes_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This language for every route."])},
  "r_auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "forgot_password_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password Request Successful"])},
    "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "otp_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP Verification"])}
  },
  "r_order": {
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Order"])},
    "create_ftl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create FTL Order"])},
    "create_ftl_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add FTL Order"])},
    "create_fcl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create FCL Order"])},
    "create_fcl_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add FCL Order"])},
    "create_ltl_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create LTL Order"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order History"])},
    "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft Order"])},
    "draft_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft Order Details"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Details"])},
    "edit_ltl_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit LTL Order"])},
    "edit_draft_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Draft Order"])}
  },
  "r_dashboard": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
  },
  "r_guide": {
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions"])}
  },
  "r_corporate": {
    "corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate"])},
    "master_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Address"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
    "contract_FTL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FTL Contract"])},
    "contract_detail_FTL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FTL Contract Detail"])},
    "goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item List"])},
    "fleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleet"])},
    "pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pool"])}
  },
  "r_finance": {
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
    "finance_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice History"])},
    "finance_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Detail"])},
    "finance_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "finance_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengembalian Dana"])}
  },
  "r_notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found"])},
  "r_switch_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch App"])},
  "r_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "r_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "r_webhook_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook History"])},
  "r_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "r_report": {
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "ar_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR Invoice"])},
    "revision_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revision History"])}
  },
  "r_live_tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleet Tracking"])},
  "r_users": {
    "list_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User List"])},
    "list_driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver List"])},
    "list_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff List"])}
  },
  "shipment_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the shipment status."])},
  "shipment": {
    "status": {
      "01": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Order"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New orders were successfully made"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blue"])}
      },
      "02": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting Payment"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders are waiting for payment"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blue"])}
      },
      "03": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Accepted"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order has been accepted by Transporter"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blue"])}
      },
      "04": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter has assigned a driver"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blue"])}
      },
      "05": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading to Pick-up Location"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver is heading to the pick-up location"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "06": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery confirmation"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sender has approved the destination data and goods"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warning"])}
      },
      "07": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Items"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items are being loaded"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "08": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading to Destination"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver is heading to the destination location"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "09": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting to Unload Items"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver is waiting for Items to be unloaded"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "10": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unloading Items"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items are being unloaded"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "11": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment has been received by the destination PIC and is awaiting confirmation of receipt"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "12": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Completed"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment has been confirmed by the Shipper"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "13": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order has been canceled"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error"])}
      },
      "14": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complained"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order has been complained"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gray"])}
      },
      "15": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaint Pending Approval"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaint ticket has been resolved. Ticket must be confirmed"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "16": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Order, Unpaid"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New orders have not been made payments"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blue"])}
      },
      "17": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for Approval"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter has added additional charges, awaiting tower control approval"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "18": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt Confirmation"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipper confirms receipts within 2 x 24 hours"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "19": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter Completed"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This order has been completed, awaiting additional charges"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "20": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected Charges"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional charges rejected by CT, please correct the data"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gray"])}
      },
      "21": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Failed"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery failed due to some reason"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error"])}
      },
      "22": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the pickup process"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The driver is in the pickup process"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "23": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Succeeded"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The driver has arrived at the original location"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      }
    }
  },
  "category_notif_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the category of notifications."])},
  "notif": {
    "category": {
      "00": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Account"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconAccount"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "01": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "02": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "03": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTruckFast"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "04": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconInvoice"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "05": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconInvoice"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green"])}
      },
      "06": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconNotification"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "07": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconTransaction"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blue"])}
      },
      "08": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IconNotification"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blue"])}
      }
    }
  },
  "draft_status_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is draft of status"])},
  "draft": {
    "status": {
      "0": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])}
      },
      "1": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blue"])}
      },
      "2": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["success"])}
      },
      "3": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error"])}
      }
    }
  }
}