import { DashboardLayout, DefaultLayout } from "@/layouts";
const dashboard = [
  {
    path: "/order",
    component: DashboardLayout,
    meta: {
      title: "r_order.transaction",
      icon: "IconTransaction",
      group: "apps",
    },
    single: false,
    hidden: false,
    children: [
      {
        path: "",
        component: DefaultLayout,
        meta: {
          title: "r_order.create",
          group: "order",
        },
        redirect: "/order",
        hidden: false,
        children: [
          // ORDER NON UMKM
          {
            path: "",
            name: "order",
            meta: {
              title: "r_order.create",
              roles: [
                "shipperagreement:read",
                "orderplanner:create",
                "orderplanner:read",
              ],
            },
            hidden: true,
            component: () =>
              import("@/views/shipper/order/order-create/OrderCreate.vue"),
          },
          // Create order ftl
          {
            path: "ftl",
            name: "order.ftl",
            meta: {
              title: "r_order.create_ftl",
              roles: [
                "shipperagreement:read",
                "orderplanner:create",
                "orderplanner:read",
              ],
            },
            hidden: true,
            component: () =>
              import(
                "@/views/shipper/order/order-create-ftl/OrderCreateFTL.vue"
              ),
          },
          {
            path: "ftl/:id",
            name: "order.ftl.form",
            meta: {
              title: "r_order.create_ftl_form",
              roles: ["orderplanner:create"],
            },
            hidden: true,
            component: () =>
              import(
                "@/views/shipper/order/order-create-ftl-form/OrderCreateFTLForm.vue"
              ),
          },
          // End create order ftl
          // Create order fcl
          {
            path: "fcl",
            name: "order.fcl",
            meta: {
              title: "r_order.create_fcl",
              roles: [
                "shipperagreement:read",
                "orderplanner:create",
                "orderplanner:read",
              ],
            },
            hidden: true,
            component: () =>
              import(
                "@/views/shipper/order/order-create-fcl/OrderCreateFCL.vue"
              ),
          },
          {
            path: "fcl/:id",
            name: "order.fcl.form",
            meta: {
              title: "r_order.create_fcl_form",
              roles: ["orderplanner:create"],
            },
            hidden: true,
            component: () =>
              import(
                "@/views/shipper/order/order-create-fcl-form/OrderCreateFCLForm.vue"
              ),
          },
          // End create order fcl
          // order LTL
          {
            path: "ltl",
            name: "order.ltl",
            meta: {
              title: "r_order.create_ltl_form",
              roles: ["orderplanner:create"],
            },
            hidden: true,
            component: () =>
              import(
                "@/views/shipper/order/order-create-ltl-form/OrderCreateLTLForm.vue"
              ),
          },

          // END ORDER NON UMKM

          // ORDER UMKM
          {
            path: "ftl-sme",
            name: "order.ftl.sme",
            meta: {
              title: "r_order.create_ftl_form",
              roles: ["orderplanner:create"],
            },
            hidden: true,
            component: () =>
              import(
                "@/views/shipper/order-sme/order-create-ftl-form/OrderCreateFTLForm.vue"
              ),
          },
        ],
      },
      {
        path: "draft",
        component: DefaultLayout,
        meta: {
          title: "r_order.draft",
          group: "order",
        },
        redirect: "/order/draft",
        hidden: false,
        children: [
          {
            path: "",
            name: "order-draft",
            meta: {
              title: "r_order.draft",
              roles: ["transactions:read"],
            },
            hidden: true,
            component: () =>
              import("@/views/shipper/order/order-draft/OrderDraft.vue"),
          },
          {
            path: "detail/:id",
            name: "order-detail-draft",
            meta: {
              title: "r_order.draft_detail",
              roles: ["transactions:read"],
            },
            hidden: true,
            component: () =>
              import(
                "@/views/shipper/order/order-detail-draft/OrderDetailDraft.vue"
              ),
          },
        ],
      },
      {
        path: "history",
        component: DefaultLayout,
        meta: {
          title: "r_order.history",
          group: "order",
        },
        redirect: "/order/history",
        hidden: false,
        children: [
          {
            path: "",
            name: "order-history",
            meta: {
              title: "r_order.history",
              roles: ["transactions:read"],
            },
            hidden: true,
            component: () =>
              import("@/views/shipper/order/order-history/OrderHistory.vue"),
          },
          {
            path: "detail/:id",
            name: "order-detail",
            meta: {
              title: "r_order.detail",
              roles: ["transactions:read"],
            },
            hidden: true,
            component: () =>
              import("@/views/shipper/order/order-detail/OrderDetail.vue"),
          },
          {
            path: "/order/edit/ltl/:id",
            name: "order.edit.ltl",
            meta: {
              title: "r_order.edit_ltl_form",
              roles: ["orderplanner:create"],
            },
            hidden: true,
            component: () =>
              import(
                "@/views/shipper/order/order-edit-ltl-form/OrderEditLTLForm.vue"
              ),
          },
          {
            path: "/order/edit/draft/:id",
            name: "order.edit.draft",
            meta: {
              title: "r_order.edit_draft_form",
              roles: ["orderplanner:create"],
            },
            hidden: true,
            component: () =>
              import(
                "@/views/shipper/order/order-edit-draft-form/OrderDraftForm.vue"
              ),
          },
        ],
      },
    ],
  },
];

export default dashboard;
