<template>
  <!-- THIS IS LIST OF LINK ITEM MENU
  IF SINGLE NOT SHOW CHILD AND REDIRECT TO FIRST CHILD
  IF NOT SINGLE WILL BE SHOW CHILD MENU AFTER CLICK
  IF ACTIVE MENU IS MARKED WITH BACKGROUND AND BOLD FONT -->
  <router-link
    :to="props.data.single ? { name: props.data.children[0].name } : ''"
    @click.prevent="toggleChild"
    :class="{
      'bg-primary-thin rounded-full font-semibold':
        (props.data.show && !props.data.single) || currentRoute,
    }"
    class="flex relative whitespace-nowrap overflow-hidden items-center gap-2 py-2 px-3 text-black text-sm hover:bg-primary-thin hover:rounded-full transition-all duration-400 ease-in-out"
  >
    <icon-svg
      :width="16"
      :height="18"
      :icon="`${props.data.meta.icon}${!currentRoute ? 'Outlined' : ''}`"
    />
    <!-- <base-icon-image :width="16" :height="18" :icon="props.data.meta.icon" /> -->
    <div
      v-if="sidebar.showSidebar"
      class="leading-[18px] line-clamp-1 max-w-[180px]"
      style="width: calc(100% - 24px)"
    >
      <!-- NAME MENU SYNC WITH I18N -->
      {{ $t(props.data.meta.title) }}
    </div>
    <icon-svg
      v-if="
        props.data.children.length && sidebar.showSidebar && !props.data.single
      "
      :class="{ 'rotate-180': props.data.show }"
      class="absolute max-sm:hidden transition-all duration-400 ease-in-out -translate-y-1/2 top-1/2 right-3.5"
      icon="IconArrowDown"
      :width="10"
      :height="8"
    />
  </router-link>
  <!-- THIS IS LIST OF SUB MENU
  IF NOT SINGLE WILL BE SHOW SUB MENU AFTER CLICK -->
  <Transition name="expand">
    <ul
      v-if="
        props.data.children.length &&
        props.data.show &&
        sidebar.showSidebar &&
        !props.data.single
      "
      class="overflow-hidden mt-2 grid"
    >
      <li
        v-for="item in props.data.children"
        :class="{ 'mb-1': !item.hidden }"
        :key="item.name"
      >
        <sub-item-navigation v-if="!item.hidden" :data="item" />
      </li>
    </ul>
  </Transition>
</template>

<script setup>
import { useMainStore } from "@/stores/main";
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import subItemNavigation from "./SubItemNavigation.vue";
import { useUserStore } from "@/stores/user";
const storeUser = useUserStore();
const sidebar = useMainStore();
const route = useRoute();
const props = defineProps({
  data: {
    type: Object,
    default: function () {
      return {
        path: "#",
        title: "Live Tracking",
        name: "live-tracking",
        icon: "icon-live-tracking",
        children: [],
      };
    },
  },
});
const currentRoute = computed(() => {
  if (props.data.children.length && props.data.single) {
    return props.data.children[0].name == route.name;
  } else if (props.data.children.length) {
    let find = props.data.children.some((x) => route.name == x.name);
    if (find) {
      return find;
    }
    let hasChildren = props.data.children.filter((x) => !!x.children);
    if (hasChildren.length) {
      let findChild = false;
      hasChildren.forEach((el) => {
        let finding = el.children.some((z) => route.name == z.name);
        if (finding) {
          findChild = true;
        }
      });
      return findChild;
    }
    return false;
  } else {
    return false;
  }
});
const toggleChild = () => {
  // if (props.data.children.length && !props.data.single) {
  sidebar.setSidebar(true);
  // setTimeout(() => {
  storeUser.toggleRoute(props.data.path);
  // }, 100);
  // }
};
onMounted(() => {
  if (currentRoute.value) {
    setTimeout(() => {
      storeUser.toggleRoute(props.data.path);
    }, 500);
  }
});
</script>
